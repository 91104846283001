.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-text10 {
  display: inline-block;
}
 
.home-text11 {
  display: inline-block;
}
 
.home-text12 {
  display: inline-block;
}
 
.home-text13 {
  display: inline-block;
}
 
.home-text14 {
  display: inline-block;
}
 
.home-text15 {
  display: inline-block;
}
 
.home-text16 {
  display: inline-block;
}
 
.home-text17 {
  display: inline-block;
}
 
.home-text18 {
  display: inline-block;
}
 
.home-text19 {
  display: inline-block;
}
 
.home-text20 {
  display: inline-block;
}
 
.home-text21 {
  display: inline-block;
}
 
.home-text22 {
  display: inline-block;
}
 
.home-text23 {
  display: inline-block;
}
 
.home-text24 {
  display: inline-block;
}
 
.home-text25 {
  display: inline-block;
}
 
.home-text26 {
  display: inline-block;
}
 
.home-text37 {
  display: inline-block;
}
 
.home-text38 {
  display: inline-block;
}
 
.home-text39 {
  display: inline-block;
}
 
.home-text40 {
  display: inline-block;
}
 
.home-text41 {
  display: inline-block;
}
 
.home-text42 {
  display: inline-block;
}
 
.home-text43 {
  display: inline-block;
}
 
.home-text44 {
  display: inline-block;
}
 
.home-text45 {
  display: inline-block;
}
 
.home-text46 {
  display: inline-block;
}
 
.home-text47 {
  display: inline-block;
}
 
.home-text48 {
  display: inline-block;
}
 
.home-text49 {
  display: inline-block;
}
 
.home-text50 {
  display: inline-block;
}
 
.home-text51 {
  display: inline-block;
}
 
.home-text52 {
  display: inline-block;
}
 
.home-text53 {
  display: inline-block;
}
 
.home-text54 {
  display: inline-block;
}
 
.home-text55 {
  display: inline-block;
}
 
.home-text56 {
  display: inline-block;
}
 
.home-text57 {
  display: inline-block;
}
 
.home-text58 {
  display: inline-block;
}
 
.home-text59 {
  display: inline-block;
}
 
.home-text60 {
  display: inline-block;
}
 
.home-text61 {
  display: inline-block;
}
 
.home-text62 {
  display: inline-block;
}
 
.home-text63 {
  display: inline-block;
}
 
.home-text64 {
  display: inline-block;
}
 
.home-text65 {
  display: inline-block;
}
 
.home-text66 {
  display: inline-block;
}
