.logos33-container1 {
  position: relative;
}
 
.logos33-max-width {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.logos33-text1 {
  text-align: center;
}
 
.logos33-logo1 {
  object-fit: contain;
}
 
.logos33-logo2 {
  object-fit: contain;
}
 
.logos33-logo3 {
  object-fit: contain;
}
 
.logos33-logo4 {
  object-fit: contain;
}
 
.logos33-logo5 {
  object-fit: contain;
}
 
.logos33-logo6 {
  object-fit: contain;
}
 
.logos33-logo7 {
  object-fit: contain;
}
 
.logos33-logo8 {
  object-fit: contain;
}
 
.logos33-logo9 {
  object-fit: contain;
}
 
.logos33-text2 {
  display: inline-block;
}
